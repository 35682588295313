<template>
  <div class="error">
    <Header></Header>
    <div class="error-container">
      <div class="error-message">
        <em class="icon icon-balls"></em>
        <span>{{ errorPageMessage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';

export default {
  name: 'Error',
  components: {
    Header,
  },
  computed: {
    ...mapGetters(['errorPageMessage']),
  },
  mounted() {
    this.$store.dispatch('toggleShopSettings', false);
  },
};
</script>

<style lang="scss" scoped>
.error {
  height: 100%;
}

.error-container {
  display: flex;
  width: 100%;
  height: calc(100% - 35px);
  align-items: center;
  justify-content: center;
}

.error-message {
  display: flex;
  align-items: center;
  flex-direction: column;

  em {
    color: rgba($color: #000000, $alpha: 0.3);
    font-size: 60px;
  }

  span {
    margin-top: 16px;
    color: rgba($color: #000000, $alpha: 0.4);
    font-size: 19px;
  }
}
</style>
